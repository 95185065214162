import type { ComponentType } from "react"

export const withCurrentYear = (Component): ComponentType => {
    return (props) => {
        const currentYear = new Date().getFullYear()

        const updatedText = `© ${currentYear} Smarter Living BV, All rights reserved`

        return <Component {...props} text={updatedText} />
    }
}
